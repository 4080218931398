<template>
    <section class="pa-4 formular packages" style="max-width: 800px; margin-left: auto; margin-right: auto;">

        <!--PACKAGES-->
        <v-row class="bg mb-2" style="border-radius: 8px; overflow: hidden;">

            <!--SILVER-->
            <v-col :cols="mobileSwitch" align="center" class="erb" style="background-color: #808080E0;">
                <v-hover v-slot="{hover}">
                    <v-card :disabled='!canPurchaseSilver' class="p_silver" :class="selectedPackage === 'Silver' ? 'select' : ''"
                            :elevation="hover ? 12 : 0" @click="choosePackage('Silver')">
                        <v-card-text class="pb-6 white--text">
                            <span
                                class="display-0 font-weight-light mb-0">{{
                                    $t('products_packages.dialog.package')
                                }}</span><br>
                            <span class="display-1 font-weight-black">{{ $t('products_packages.dialog.silver') }}</span><br>
                            <span class="display-0 font-weight-light"
                                  style="font-size: 1.3em !important;">{{ $t('products_packages.dialog.p2') }}</span>
                        </v-card-text>
                        <v-card-text class="points_height white--text">
                            <ul>
                                <li>{{ $t('products_packages.dialog.point_1', {year: '5', price: '2 000'}) }}</li>
                                <li>{{
                                        $t('products_packages.dialog.point_2', {target_price: '40 000', price: '2 000'})
                                    }}
                                </li>
                                <li>{{
                                        $t('products_packages.dialog.point_3', {target_price: '40 000', price: '2 000'})
                                    }}
                                </li>
                                <li>{{ $t('products_packages.dialog.point_4') }}</li>
                                <li>{{ $t('products_packages.dialog.point_5') }}</li>
                            </ul>
                        </v-card-text>
                        <v-card-text class="white--text">
                            <span class="display-0 font-weight-light"
                                  style="font-size: 1.2em !important">{{
                                    $t('products_packages.dialog.target_price')
                                }}</span><br>
                            <span class="display-1 font-weight-black">
                        <div class="stroke"></div>
                        <div>6 000 €</div>
                    </span>
                            <span class="display-2 font-weight-medium"
                                  style="font-size: 1.4em !important">{{
                                    $t('products_packages.dialog.now')
                                }}</span><br>
                            <span class="display-0 font-weight-black" style="font-size: 3.5em !important">2 000 €</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>

            <!--GOLD-->
            <v-col :cols="mobileSwitch" align="center" class="erb" style="background-color: #BFA149E0;">
                <v-hover v-slot="{hover}">
                    <v-card :disabled='!canPurchaseGold' class="p_gold" :class="{'select' : selectedPackage === 'Gold'}" :elevation="hover ? 12 : 0"
                            @click="choosePackage('Gold')">
                        <v-card-text class="pb-6 white--text">
                            <span
                                class="display-0 font-weight-light mb-0">{{
                                    $t('products_packages.dialog.package')
                                }}</span><br>
                            <span
                                class="display-1 font-weight-black">{{ $t('products_packages.dialog.gold') }}</span><br>
                            <span class="display-0 font-weight-light"
                                  style="font-size: 1.3em !important;">{{ $t('products_packages.dialog.p3') }}</span>
                        </v-card-text>
                        <v-card-text class="points_height white--text">
                            <ul>
                                <li>{{ $t('products_packages.dialog.point_1', {year: '10', price: '4 000'}) }}</li>
                                <li>{{
                                        $t('products_packages.dialog.point_2', {
                                            target_price: '80 000',
                                            price: '4 000'
                                        })
                                    }}
                                </li>
                                <li>{{
                                        $t('products_packages.dialog.point_3', {
                                            target_price: '80 000',
                                            price: '4 000'
                                        })
                                    }}
                                </li>
                                <li>{{ $t('products_packages.dialog.point_4') }}</li>
                                <li>{{ $t('products_packages.dialog.point_5') }}</li>
                                <li>{{ $t('products_packages.dialog.point_6') }}</li>
                                <li>{{ $t('products_packages.dialog.point_7') }}</li>
                            </ul>
                        </v-card-text>
                        <v-card-text class="white--text">
                            <span class="display-0 font-weight-light"
                                  style="font-size: 1.2em !important">{{
                                    $t('products_packages.dialog.target_price')
                                }}</span><br>
                            <span class="display-1 font-weight-black">
                            <div class="stroke"></div>
                            <div>16 000 €</div>
                        </span>
                            <span class="display-2 font-weight-medium"
                                  style="font-size: 1.4em !important">{{
                                    $t('products_packages.dialog.now')
                                }}</span><br>
                            <span class="display-0 font-weight-black" style="font-size: 3.5em !important">4 000 €</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>

            <!--BUSINESS-->
            <v-col :cols="mobileSwitch" align="center" class="erb" style="background-color: #26428CE0;">
                <v-hover v-slot="{hover}">
                    <v-card :disabled='!canPurchaseBusiness' class="p_business" :class="{'select' : selectedPackage === 'Business'}"
                            :elevation="hover ? 12 : 0" @click="choosePackage('Business')">
                        <v-card-text class="pb-6 white--text">
                            <span
                                class="display-0 font-weight-light mb-0">{{
                                    $t('products_packages.dialog.package')
                                }}</span><br>
                            <span
                                class="display-1 font-weight-black">{{ $t('products_packages.dialog.business') }}</span><br>
                            <span class="display-0 font-weight-light"
                                  style="font-size: 1.3em !important;">{{ $t('products_packages.dialog.p4') }}</span>
                        </v-card-text>
                        <v-card-text class="points_height white--text">
                            <ul>
                                <li>{{ $t('products_packages.dialog.point_1a') }}</li>
                                <li>{{
                                        $t('products_packages.dialog.point_2', {
                                            target_price: '500 000',
                                            price: '25 000'
                                        })
                                    }}
                                </li>
                                <li>{{
                                        $t('products_packages.dialog.point_3', {
                                            target_price: '500 000',
                                            price: '25 000'
                                        })
                                    }}
                                </li>
                                <li>{{ $t('products_packages.dialog.point_4') }}</li>
                                <li>{{ $t('products_packages.dialog.point_5') }}</li>
                                <li>{{ $t('products_packages.dialog.point_6') }}</li>
                                <li>{{ $t('products_packages.dialog.point_7a') }}</li>
                                <li>{{ $t('products_packages.dialog.point_8') }}</li>
                                <li>{{ $t('products_packages.dialog.point_9') }}</li>
                            </ul>
                        </v-card-text>
                        <v-card-text class="white--text">
                            <span class="display-0 font-weight-light"
                                  style="font-size: 1.2em !important">{{
                                    $t('products_packages.dialog.target_price')
                                }}</span><br>
                            <span class="display-1 font-weight-black">
                            <div class="stroke"></div>
                            <div>80 000 €</div>
                        </span>
                            <span class="display-2 font-weight-medium"
                                  style="font-size: 1.4em !important">{{
                                    $t('products_packages.dialog.now')
                                }}</span><br>
                            <span class="display-0 font-weight-black"
                                  style="font-size: 3.5em !important">25 000 €</span>
                        </v-card-text>
                    </v-card>
                </v-hover>
            </v-col>

        </v-row>

        <!--SELLER-->
        <v-row :class="{ 'rgg-grey' : this.$vuetify.theme.dark }" class="grey lighten-4 rounded mb-2">
            <v-col cols="12">
                <v-card flat outlined>
                    <v-card-text>
                        <span class="headline">
                            {{ seller_data.name + " " + seller_data.surname }}
                            <span class="gold--text" v-if="seller_data.companyName !== ''">
                                - {{ seller_data.companyName }}
                            </span>
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-btn class="gold lighten-1 white--text" depressed small
                       @click="dialog_sellers = true">
                    <v-icon left>mdi-account-tie</v-icon>
                    <span>{{ $t('products_packages.dialog.change_seller') }}</span>
                </v-btn>
            </v-col>
        </v-row>

        <!--BUY BUTTON-->
        <v-row>
            <v-col align="center">
                <v-btn v-if="button_buy" depressed text class="gold white--text px-8" @click="purchase">
                    <v-icon left>mdi-package-variant-closed</v-icon>
                    <span>{{ $t('products_packages.dialog.buy') }}</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog_sellers"
                  persistent scrollable fullscreen
        >
            <v-card flat v-if="dialog_sellers">
                <v-toolbar fixed dark dense color="gold" style="z-index: 10; flex: unset;">
                    <v-btn icon dark @click="dialog_sellers = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        <span>{{ $t('products_agreements.dialog.sellers') }}</span>
                    </v-toolbar-title>

                </v-toolbar>
                <v-card-text class="pt-0 px-0">
                    <DataSelect :data_view_name="'sellers_list_select'"/>
                </v-card-text>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import DataSelect from "@/components/DataSelect";
import AuthMixin from "@/services/auth/auth_mixin";
import {purchasePackage} from "@/api/packages";
//import Permissions from "@/services/auth/permissions";

export default {
    name: "PackagePurchaseDialog",
    components: {DataSelect},
    data: () => ({
        dialog_sellers: false,
        user_type: undefined,
        button_buy: false,
        points_height: 640,
        selected_package: "",
        seller_data: {}
    }),
    mixins: [AuthMixin],
    computed: {
        users() {
            return this.$store.getters.getUsers;
        },
        userType() {
            return this.user_type;
        },
        getSelectedSeller() {
            return this.$store.getters.getSelectedSeller;
        },
        selectedPackage() {
            return this.selected_package;
        },
        mobileSwitch() {
            return this.$vuetify.breakpoint.width <= 500 ? 12 : 4;
        },
        canSellPackage() {
            return this.$store.getters.getSellerInfo.canSellPackage
        },
        canPurchasePackage() {
            return this.$store.getters.getSellerInfo.canPurchasePackage
        },
        hasPurchasedPackage() {
            return this.$store.getters.getSellerInfo.hasPurchasedPackage
        },
        getSellerPosition() {
            return this.$store.getters.getSellerInfo.position
        },
        canPurchaseSilver() {
            // if (this.canSellPackage === false) {
            //     if (this.getSellerPosition !== "P1" || this.canPurchasePackage === false) {
            //         return false
            //     }
            // }

            return true
        },
        canPurchaseGold() {
            // if (this.canSellPackage === false) {
            //     if ((this.getSellerPosition !== "P1" && this.getSellerPosition !== "P2") || this.canPurchasePackage === false) {
            //         return false
            //     }
            // }

            return true
        },
        canPurchaseBusiness() {
            // if (this.canSellPackage === false) {
            //     if ((this.getSellerPosition !== "P1" && this.getSellerPosition !== "P2" && this.getSellerPosition !== "P3") || this.hasPurchasedPackage === true) {
            //         return false
            //     }
            // }

            return true
        }
    },
    methods: {
        choosePackage(_package) {
            if ((_package === 'Silver' && !this.canPurchaseSilver) || (_package === 'Gold' && !this.canPurchaseGold) || (_package === 'Business' && !this.canPurchaseBusiness)) {
                return false
            }
            this.selected_package = _package;
            this.button_buy = true;
        },

        selectSeller(_sellerID) {
            this.$store.dispatch('getSeller', _sellerID)
                .then(() => {
                    this.seller_data = this.getSelectedSeller;
                    this.$root.$emit('overlay', false);
                    this.dialog_sellers = false;
                }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'info',
                    msg: 'notification.server_error'
                });
            })
        },

        purchase() {
            if (this.selected_package === null) {
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'exclamation',
                    msg: 'products_packages.purchase_validation'
                })
            }

            this.$root.$emit('overlay', true);
            let packageForm = {
                sellerId: this.seller_data.id,
                type: this.selected_package
            }
            purchasePackage(packageForm).then(() => {
                this.$root.$emit('overlay', false);
                this.callback('close');
                this.$root.$emit('notification', {
                    type: 'success',
                    icon: 'update',
                    msg: 'products_packages.purchase_notify'
                })
            }).catch(() => {
                this.$root.$emit('overlay', false);
                this.$root.$emit('notification', {
                    type: 'error',
                    icon: 'exclamation',
                    msg: 'products_packages.purchase_error'
                })
            })
        },

        callback(e) {
            this.$parent.$emit('call', {func: e, data: null});
        },
    },

    created() {
    },

    mounted() {
        this.$store.dispatch('getSeller', this.$store.getters.getUserId)
            .then(() => {
                Object.assign(this.seller_data, this.getSelectedSeller);
                this.$forceUpdate();
                this.$root.$emit('overlay', false);
            }).catch(() => {
            this.$root.$emit('overlay', false);
            this.$root.$emit('notification', {
                type: 'error',
                icon: 'info',
                msg: 'notification.server_error'
            });
        })

        this.$root.$on('item-seller', (id) => {
            this.selectSeller(id);
        });
    },

    destroyed() {
        this.$store.dispatch('clearSeller');
        this.$root.$off('item-seller');
    }
}
</script>

<style>

.packages .points_height {
    height: 480px;
}

.packages .bg {
    background-image: url('/images/package_bg.jpg');
    background-size: cover;
}

.packages .erb {
    background-image: url('/images/package_erb.png');
    background-size: 80%;
    background-position: center center;
}

.packages .stroke {
    background-image: url('/images/package_line.png');
    background-size: 70%;
    background-position: center center;
    position: absolute;
    width: 100%;
    height: 42px;
}

.packages ul {
    white-space: pre-line;
}

.packages ul li {
    font-size: 1.0em;
    text-align: left;
    padding-bottom: 0.2em;
}

.packages .v-card.p_silver,
.packages .v-card.p_gold,
.packages .v-card.p_business {
    background-color: rgba(255, 255, 255, 0.0);
    border: 4px solid transparent;
}

.packages .v-card.select {
    background-color: rgba(255, 255, 255, 0.3);
    border-color: white;
}

</style>